import axios, { AxiosResponse } from "axios";
import { APIResponse } from "../../models/models";
import {IAuth, IDepartment, IRole, IUnit} from "../model/ManagerModel";
import { DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE } from "../../utils/PageUtils";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
const API_URL = (localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["ssoUrl"] || process.env.REACT_APP_API_URL_SSO);
const API_XADMIN_URL = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["adminUrl"] || process.env.REACT_APP_XADMIN_ENDPOINT;

export const getListAuth = (searchData?: any): Promise<AxiosResponse<any>> => {
  let config = {
    params: {
      ...searchData,
      pageSize: searchData?.pageSize || MAX_PAGE_SIZE,
      pageIndex: searchData?.pageIndex || DEFAULT_PAGE_INDEX,
    },
  };
  let url = `${API_URL}/authorities/search`;
  return axios.get(url, config);
};
export const getListRole = (searchData?: any): Promise<AxiosResponse<any>> => {
  let config = {
    params: {
      ...searchData,
      pageSize: searchData?.pageSize || MAX_PAGE_SIZE,
      pageIndex: searchData?.pageIndex || DEFAULT_PAGE_INDEX,
    },  
  };
  let url = `${API_URL}/roles/search`;
  return axios.get(url, config);
};
export const getListUnit = (searchData?: any) => {
  let config = {
    params: {
      ...searchData,
      pageSize: searchData?.pageSize || MAX_PAGE_SIZE,
      pageIndex: searchData?.pageIndex || DEFAULT_PAGE_INDEX,
    },  
  };
  let url = `${API_URL}/organizations/page`;
  return axios.get(url, config);
};

export const updateAuth = (id: string, auth: IAuth): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/authorities/${id}`;
  return axios.put(url, auth);
};
export const addNewAuth = (auth: IAuth): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/authorities`;
  return axios.post(url, auth);
};
export const deleteAuth = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/authorities/${ids}`;
  return axios.delete(url);
};
export const updateRole = (id: string, role: IRole): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/roles/${id}`;
  return axios.put(url, role);
};

export const addNewRole = (role: IRole): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/roles`;
  return axios.post(url, role);
};

export const deleteRole = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/roles/${ids}`;
  return axios.delete(url);
};
export const getAllRole = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/roles`;
  return axios.get(url);
};
export const updateUnit = (id: string, unit: IUnit): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/organizations/${id}`;
  return axios.put(url, unit);
};

export const addNewUnit = (unit: IUnit): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/organizations`;
  return axios.post(url, unit);
};

export const deleteUnit = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
  let config = {
    params :{
      ids :ids?.toString()
    },  
  }
  let url = `${API_URL}/organizations/list-id`;
  return axios.delete(url,config);
};

export const getListDepartment = (searchData?: any) => {
  let config = {
    params: {
      ...searchData,
      pageSize: searchData?.pageSize || MAX_PAGE_SIZE,
      pageIndex: searchData?.pageIndex || DEFAULT_PAGE_INDEX,
    },
  };
  let url = `${API_XADMIN_URL}/departments/search`;
  return axios.get(url, config);
};

export const updateDepartment = (department: IDepartment): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_XADMIN_URL}/departments/${department?.id}`;
  return axios.put(url, department);
};

export const addNewDepartment = (department: IDepartment): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_XADMIN_URL}/departments`;
  return axios.post(url, department);
};

export const deleteDepartment = (id: string): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_XADMIN_URL}/departments/${id}`;
  return axios.delete(url);
};

export const getListOrg = (searchData?: any) => {
  let config = {
    params: {
      ...searchData,
      pageSize: searchData?.pageSize || MAX_PAGE_SIZE,
      pageIndex: searchData?.pageIndex || DEFAULT_PAGE_INDEX,
    },
  };
  let url = `${API_XADMIN_URL}/organizations/search`;
  return axios.get(url, config);
};
