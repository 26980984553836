import {User, UserByOrg} from "../../model/UserModels";


export const INIT_USER: UserByOrg = {
  id: "",
  personId: "",
  fullName: "",
  gender: "",
  birthDate: "",
  departmentDefCode: "",
  departmentObject: null,
  codeDepartments: [],
  phoneNumber: "",
  email: "",
  idNumber: "",
  user: {
    username: "",
    password: "",
    confirmPassword: "",
    roles: null,
  } as User,
  organization: null,
  orgId: "",
  createDate: "",
  createdBy: "",
  modifiedBy: "",
  modifyDate: "",
  active: true,
};

export const USER_VARIABLE = {
  IS_CHANGE_PASSWORD: "user.isChangePassword"
}