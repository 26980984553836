import { useFormik } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { getListDepartment, getListRole } from "../../services/ManagerServices";
import { addNewPerson, addNewUser, getPersonByUserId, updatePerson, updateUser } from "../../services/UserServices";
import Autocomplete from "../../../component/input-field/Autocomplete";
import { INIT_USER, USER_VARIABLE } from "../const/UserConst";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { VARIABLE } from "../../../../Constant";
import { REGEX, VARIABLE_STRING, LIST_GENDER, TYPE } from "../../../utils/Constant";
import { Org, UserByOrg } from "../../model/UserModels";
import {
  handleThrowResponseMessage,
  isSuccessfulResponse,
} from "../../../../AppFunction";
import { columnNamesTypeTableCollapse, OctInputSearch, OctTableCollapse, OctTextValidator } from "@oceantech/oceantech-ui";
import { getListOrganizations, getOrganizationById } from "../../services/OrganizationServices";
import moment from "moment";
import { IObject } from "../../../models/models";

interface IProps {
  handleCloseDialog: () => void;
  userInfo: UserByOrg;
  isView?: boolean;
}
const UserDialog: FC<IProps> = (props) => {
  const { handleCloseDialog, userInfo, isView = false } = props;
  const { lang } = useMultiLanguage();
  const orgListDataRef = useRef<Org[]>([]);
  const [isUser, setIsUser] = useState<boolean>(false);

  const [openOrganizationDialog, setOpenOrganizationDialog] = useState<boolean>(false);
  const [orgListData, setOrgListData] = useState<Org[]>([]);
  const [departmentListData, setDepartmentListData] = useState<Org[]>([]);
  const [searchObject, setSearchObject] = useState<IObject>({ pageIndex: 1, pageSize: 1000 });
  const [keyWord, setKeyWord] = useState<any>(null);
  const [orgName, setOrgName] = useState<any>(null);
  const [selectedCodeDepartment, setSelectedCodeDepartment] = useState<Org[]>([]);
  const [showPerson, setShowPerson] = useState<boolean>(false);


  const validationSchema = Yup.object().shape({
    employee: isUser
      ? Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
      : Yup.object().nullable(),

    ...(showPerson ? {
      fullName: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
      gender: Yup.string().nullable().required(lang("VALIDATION.REQUIRE")),
      birthDate: Yup.string().required(lang("VALIDATION.REQUIRE")).test('is-valid-date', lang("VALIDATION.INVALID_BIRTHDATE"), value => validateDate(value)),
      departmentDefCode: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
      departmentObject: Yup.object().nullable(true).required(lang("VALIDATION.REQUIRE")),
    } : {}),
    email: Yup.string().email(lang("VALIDATION.EMAILINVALID")).nullable(true).required(lang("VALIDATION.REQUIRE")),
    phoneNumber: Yup.string()
      .matches(REGEX.CHECK_PHONE, lang("VALIDATION.ISPHONE"))
      .nullable(true).required(lang("VALIDATION.REQUIRE")),
    user: Yup.object().shape({
      email: Yup.string().email(lang("VALIDATION.EMAILINVALID")).nullable(true),
      phoneNumber: Yup.string()
        .matches(REGEX.CHECK_PHONE, lang("VALIDATION.ISPHONE"))
        .nullable(true),
      password: !userInfo?.user?.id
        ? Yup.string()
          .required(lang("VALIDATION.REQUIRE"))
          .min(8, lang("REQUIRE_8"))
          .nullable()
        : Yup.mixed().nullable(),
      confirmPassword: !userInfo?.user?.id
        ? Yup.string()
          .required(lang("VALIDATION.REQUIRE"))
          .oneOf([Yup.ref("password"), null], lang("PASSWORD_INVALID"))
          .nullable()
        : Yup.mixed().nullable(),
      username: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
      roles: Yup.array()
        .of(Yup.object())
        .required(lang("VALIDATION.REQUIRE"))
        .nullable(true),
    }),
  });

  const handleSubmit = async (values: UserByOrg) => {
    const data: any = convertSubmitData(values);
    const dataPerson: any = convertSubmitDataPerson(values);

    try {
      const res: any = values?.user?.id
        ? await updateUser(values?.user?.id, data)
        : await addNewUser(data);
      if (isSuccessfulResponse(res?.data?.code)) {
        if (showPerson) {
          handleSavePerson(res?.data?.data, dataPerson);
        } else {
          toast.success(
            userInfo.id
              ? lang("TOAST.EDIT.SUCCESS")
              : lang("TOAST.CREATE.SUCCESS")
          );
          handleCloseDialog();
        }
      } else {
        handleThrowResponseMessage(res);
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleSavePerson = async (userData: any, dataPerson: any) => {
    if (!userData?.id) return;
    try {
      const dataPersonSend = {
        ...dataPerson,
        userId: userData?.id,
      }
      const res: any = dataPerson?.personId ? await updatePerson(dataPerson?.personId, dataPersonSend)
        : await addNewPerson(dataPersonSend);
      if (isSuccessfulResponse(res?.data?.code)) {
        toast.success(
          userInfo.id
            ? lang("TOAST.EDIT.SUCCESS")
            : lang("TOAST.CREATE.SUCCESS")
        );
        handleCloseDialog();
      } else {
        formik.setFieldValue("user.id", userData?.id);
        handleThrowResponseMessage(res);
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const formik = useFormik({
    initialValues: INIT_USER,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (userInfo?.user?.id) {
      getDataPersonByUserId(userInfo?.user?.id, userInfo);
    } else {
      handleGetListOrganization()
    };
  }, [userInfo]);

  useEffect(() => {
    if (formik.values?.orgId) {
      handleGetListDepartmentByOrg(formik.values?.orgId);
    };
  }, [formik.values?.orgId]);

  useEffect(() => {
    handleCheckDepartmentSelect();
  }, [formik.values?.departmentDefCode, departmentListData]);

  const init = async (orgId: string) => {
    await handleGetListOrganization(orgId);
    await handleGetOrganizationById(orgId);
  };

  const getDataPersonByUserId = async (userId: string, userInfo: any) => {
    try {
      const { data }: any = await getPersonByUserId(userId);
      if (data?.data) {
        setShowPerson(true);
        init(data.data?.orgId);
        formik?.setValues({
          ...INIT_USER,
          ...userInfo,
          ...data.data,
          personId: data.data?.id,
          user: data.data?.user || userInfo?.user,
          phoneNumber: data.data?.phoneNumber || userInfo?.phone,
          birthDate: data.data?.birthDate ? moment(data.data?.birthDate).format("DD/MM/YYYY") : "",
        });
        setSelectedCodeDepartment(data.data?.departments);
      } else {
        formik?.setValues({
          ...INIT_USER,
          ...userInfo,
          phoneNumber: userInfo?.phone,
        });
      }
    } catch (error) {
      console.error("ERROR", error);
    }
  };

  const handleChangeSelect = (name: string, data: any) => {
    switch (name) {
      case VARIABLE_STRING.USER_ROLES:
        formik.setFieldValue(name, data);
        formik.setFieldValue("employee", null);
        break;
      case USER_VARIABLE.IS_CHANGE_PASSWORD:
        formik.setFieldValue(name, data);
        formik.setFieldValue("confirmPassword", "");
        break;
      default:
        formik.setFieldValue(name, data);
    }
  };

  const convertSubmitData = (values: UserByOrg) => ({
    ...values,
    ...values.user,
    phone: values?.phoneNumber,
    email: values?.email,
    orgId: values?.orgId,
    password:
      userInfo?.user?.id && !values.user?.isChangePassword
        ? VARIABLE.DEFAULT_PASSWORD
        : values.user.password,
    confirmPassword:
      userInfo?.user?.id && !values.user?.isChangePassword
        ? VARIABLE.DEFAULT_PASSWORD
        : values.user.confirmPassword,
    accountNonExpired: true,
    accountNonLocked: true,
    active: values.active,
    isChangePassword: values.user?.isChangePassword,
    credentialsNonExpired: true,
    justCreated: true,
    roles: values.user?.roles?.map((item: any) => item.name),
  });

  const convertSubmitDataPerson = (values: UserByOrg) => {
    const monentBirthDate = moment(values.birthDate, "DD/MM/YYYY");
    return ({
      ...values,
      birthDate: monentBirthDate.format("YYYY-MM-DD"),
      dobDay: monentBirthDate.date(),
      dobMonth: monentBirthDate.month() + 1,
      dobYear: monentBirthDate.year(),
      idNumber: values.idNumber,
      codeDepartments: values.codeDepartments,
      user: {
        ...values.user,
        fullName: values?.fullName,
        gender: values?.gender,
        phone: values?.phoneNumber,
        email: values?.email,
        password:
          userInfo?.user?.id && !values.user?.isChangePassword
            ? VARIABLE.DEFAULT_PASSWORD
            : values.user.password,
        confirmPassword:
          userInfo?.user?.id && !values.user?.isChangePassword
            ? VARIABLE.DEFAULT_PASSWORD
            : values.user.confirmPassword,
        accountNonExpired: true,
        accountNonLocked: true,
        active: values.active,
        isChangePassword: values.user?.isChangePassword,
        credentialsNonExpired: true,
        justCreated: true,
        orgId: values?.orgId,
        roles: values.user?.roles?.map((item: any) => item.name),
      },
    })
  };

  const handleSelectOrganization = () => {
    setSelectedCodeDepartment([]);
    formik?.setFieldValue("departmentObject", null);
    const findCheckedItem = (items: any[]): any => {
      for (const item of items) {
        if (item.checked) {
          return item;
        }
        if (item.subs.length > 0) {
          const checkedItem = findCheckedItem(item.subs);
          if (checkedItem) {
            return checkedItem;
          }
        }
      }
      return null;
    };
  
    const checkedItem = findCheckedItem(orgListData);
    if (checkedItem) {
      formik.setFieldValue("orgId", checkedItem.id);
      setOrgName(checkedItem.name);
      setOpenOrganizationDialog(false);
    } else {
      toast.warning(lang("TOAST.SELECT_ORGANIZATION"));
    }
  };

  const handleGetListOrganization = async (orgId?: string) => {
    try {
      const res = await getListOrganizations({ ...searchObject, keyword: keyWord, orgId: orgId });
      const { code, data } = res.data;
      if (isSuccessfulResponse(code)) {
        setOrgListData(data?.content);
        orgListDataRef.current = data?.content;
      } else {
        handleThrowResponseMessage(res);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleGetOrganizationById = async (id: any) => {
    try {
      if (!id) return;
      const res = await getOrganizationById(id);
      const { code, data } = res.data;
      if (isSuccessfulResponse(code)) {
        setOrgName(data?.name);
        handleCheckboxClick(data);
      } else {
        handleThrowResponseMessage(res);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleCheckboxClick = (data: any) => {
    const updateCheckedStatus = (items: any[], id: string, checked: boolean): any[] => {
      return items.map(item => {
        if (item.id === id) {
          return {
            ...item,
            checked: checked,
            subs: updateCheckedStatus(item.subs || [], id, checked)
          };
        } else {
          return {
            ...item,
            checked: item.id === data?.parentId ? item.checked : false,
            subs: updateCheckedStatus(item.subs || [], id, checked)
          };
        }
      });
    };
    const updatedList = updateCheckedStatus(orgListDataRef.current, data?.id, true);
    setOrgListData(updatedList);
    orgListDataRef.current = updatedList;
  };

  const handleGetListDepartmentByOrg = async (orgId?: string) => {
    try {
      if (!orgId) return;
      const res = await getListDepartment({ ...searchObject, orgId: orgId });
      const { code, data } = res.data;
      if (isSuccessfulResponse(code)) {
        setDepartmentListData(data?.content);
      } else {
        handleThrowResponseMessage(res);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const validateDate = (value: string | null | undefined): boolean => {
    if (!value) {
      return false;
    }
    const formats = ['YYYY', 'DD/MM/YYYY'];
    return formats.some(format => moment(value, format, true).isValid());
  };

  const handleChangeDepartmentRelated = (listSelected: Org[]) => {
    const listCodeDepartment = listSelected?.map(item => item.code);
    handleChangeSelect("codeDepartments", listCodeDepartment);
  };

  const handleCheckDepartmentSelect = () => {
    const findItem = departmentListData.find((item) => item.code === formik.values.departmentDefCode);
    if (findItem) {
      formik.setFieldValue("departmentObject", findItem);
    } else {
      formik.setFieldValue("departmentObject", null);
    }
  };

  const columns: columnNamesTypeTableCollapse[] = [
    {
      name: "",
      field: "",
      headerStyle: {
        minWidth: "50px",
      },
      cellStyle: {
        minWidth: "50px",
        textAlign: "center",
        justifyContent: "center"
      },
      render(data, index, numericalOrder, itemList) {
        return (
          <div className='d-flex align-items-center justify-content-center custom-checkbox'>
            <input
              className='w-18px h-18px'
              type='checkbox'
              checked={data?.checked || false}
              onClick={() => handleCheckboxClick(data)}
            />
          </div>
        )
      },
    },
    {
      name: lang("UNIT.NAME"),
      field: "name",
      headerStyle: {
        minWidth: "350px",
      },
      cellStyle: {
        minWidth: "350px",
      },
      isCollapse: true,
    },
    {
      name: lang("UNIT.CODE"),
      field: "code",
      type: TYPE.NUMBER,
      headerStyle: {
        minWidth: "120px",
        maxWidth: "150px",
      },
      cellStyle: {
        minWidth: "120px",
        maxWidth: "150px",
      },
    },
    {
      name: lang("UNIT.TYPE"),
      field: "type",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        minWidth: "150px",
      },
    },
  ];

  return (
    <>
      <Modal show={true} onHide={handleCloseDialog} size="lg" centered>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {isView ? lang("USER.VIEW") : lang(!userInfo?.user?.id ? "USER.ADD" : "USER.EDIT")}

              <i
                className={`bi ${isView ? "bi-eye" : userInfo?.id ? "bi-pencil-square" : " bi-plus-circle"
                  } text-primary ps-2 fs-5`}
              ></i>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showPerson &&
              <Row className="px-3">
                <Col xs={12}>
                  <div className="fw-bold spaces fs-16 mb-8 text-primary">{"Thông tin cá nhân"}</div>
                </Col>
                <Col xs={4}>
                  <OctTextValidator
                    name="fullName"
                    lable={lang("WELFARE.EMPLOYEE.FULLNAME")}
                    type={"text"}
                    onChange={formik.handleChange}
                    value={formik?.values?.fullName}
                    errors={formik?.errors?.fullName}
                    touched={formik?.touched?.fullName}
                    isRequired
                    disabled={isView}
                    className="custom-text-validator"
                  />
                </Col>
                <Col xs={2}>
                  <Autocomplete
                    isRequired
                    lable={lang("PERSON.GENDER")}
                    options={LIST_GENDER}
                    value={LIST_GENDER.find(item => item.code === formik?.values?.gender) || null}
                    name="gender"
                    onChange={(selectedOption) =>
                      handleChangeSelect("gender", selectedOption?.code)
                    }
                    errors={formik?.errors?.gender}
                    touched={formik?.touched?.gender}
                    isDisabled={isView}
                  />
                </Col>
                <Col xs={3}>
                  <OctTextValidator
                    name="birthDate"
                    lable={lang("INPUT.BIRTHDAY")}
                    type={"text"}
                    placeholder="DD/MM/YYYY"
                    onChange={formik.handleChange}
                    value={formik?.values?.birthDate}
                    errors={formik?.errors?.birthDate}
                    touched={formik?.touched?.birthDate}
                    isRequired
                    disabled={isView}
                    className="custom-text-validator"
                  />
                </Col>
                <Col xs={3}>
                  <OctTextValidator
                    name="idNumber"
                    lable={lang("PERSON.ID_NUMBER")}
                    type={"text"}
                    onChange={formik.handleChange}
                    value={formik?.values?.idNumber}
                    errors={formik?.errors?.idNumber}
                    touched={formik?.touched?.idNumber}
                    disabled={isView}
                    className="custom-text-validator"
                  />
                </Col>
                <Col xs={4} className="pt-4">
                  <OctTextValidator
                    name="email"
                    lable={lang("USER.EMAIL")}
                    type={"text"}
                    onChange={formik.handleChange}
                    value={formik?.values?.email}
                    isRequired
                    errors={formik?.errors?.email}
                    touched={formik?.touched?.email}
                    disabled={isView}
                    className="custom-text-validator"
                  />
                </Col>
                <Col xs={4} className="pt-4">
                  <OctTextValidator
                    name="phoneNumber"
                    lable={lang("USER.PHONE")}
                    type={"text"}
                    onChange={formik.handleChange}
                    value={formik?.values?.phoneNumber}
                    isRequired
                    errors={formik?.errors?.phoneNumber}
                    touched={formik?.touched?.phoneNumber}
                    disabled={isView}
                    className="custom-text-validator"
                  />
                </Col>
              </Row>
            }
            <Row className="p-3">
              <Col xs={3}>
                <div className="fw-bold spaces fs-16 mb-8 text-primary">{"Thông tin tài khoản"}</div>
              </Col>
              <Col xs={9}>
                {!formik.values?.personId && (
                  <Col xs={4}>
                    <Form.Check
                      type="switch"
                      className="ml-0 spaces mt-5"
                      name="isCreatePerson"
                      onChange={(e) => setShowPerson(e.target.checked)}
                      checked={showPerson}
                      label={lang("USER.CREATEPERSON")}
                    />
                  </Col>
                )}
              </Col>
              <Col xs={showPerson ? 3 : 4}>
                <OctTextValidator
                  name="user.username"
                  lable={lang("USER.ACCOUNT")}
                  type={"text"}
                  onChange={formik.handleChange}
                  value={formik?.values?.user?.username}
                  errors={formik?.errors?.user?.username}
                  touched={formik?.touched?.user?.username}
                  readOnly={Boolean(userInfo?.id)}
                  isRequired
                  disabled={isView}
                  className="custom-text-validator"
                />
              </Col>
              {!showPerson &&
                <>
                  <Col xs={4}>
                    <OctTextValidator
                      name="email"
                      lable={lang("USER.EMAIL")}
                      type={"text"}
                      onChange={formik.handleChange}
                      value={formik?.values?.email}
                      isRequired
                      errors={formik?.errors?.email}
                      touched={formik?.touched?.email}
                      disabled={isView}
                      className="custom-text-validator"
                    />
                  </Col>
                  <Col xs={4}>
                    <OctTextValidator
                      name="phoneNumber"
                      lable={lang("USER.PHONE")}
                      type={"text"}
                      onChange={formik.handleChange}
                      value={formik?.values?.phoneNumber}
                      isRequired
                      errors={formik?.errors?.phoneNumber}
                      touched={formik?.touched?.phoneNumber}
                      disabled={isView}
                      className="custom-text-validator"
                    />
                  </Col>
                </>
              }
              {showPerson &&
                <>
                  <Col xs={5}>
                    <Row>
                      <Col xs={isView ? 12: 9}>
                        <OctTextValidator
                          isRequired
                          name="orgName"
                          lable={lang("GENERAL.CURRENT_ORGANIZATION")}
                          type={"text"}
                          value={orgName}
                          readOnly
                          disabled={isView}
                          className="custom-text-validator"
                        />
                      </Col>
                      {!isView && 
                      <Col xs={3} className="pt-6">
                        <Button
                          className="btn btn-primary btn-sm"
                          onClick={() => setOpenOrganizationDialog(true)}
                          hidden={isView}
                        >
                          {lang("BTN.SELECT")}
                        </Button>
                      </Col>
                      }
                    </Row>
                  </Col>
                  <Col xs={4}>
                    <Autocomplete
                      isRequired
                      lable={lang("USER.ROLE")}
                      options={[]}
                      isMulti
                      value={formik?.values?.user?.roles || null}
                      name="user.roles"
                      getOptionValue={(option) => option.name}
                      onChange={(selectedOption) =>
                        handleChangeSelect("user.roles", selectedOption)
                      }
                      searchFunction={getListRole}
                      searchObject={{}}
                      errors={formik?.errors?.user?.roles}
                      touched={formik?.touched?.user?.roles}
                      isDisabled={isView}
                    />
                  </Col>
                </>
              }
            </Row>
            <Row className="px-3">
              {!showPerson &&
                <Col xs={4} className="pt-4">
                  <Autocomplete
                    isRequired
                    lable={lang("ROLE")}
                    options={[]}
                    isMulti
                    value={formik?.values?.user?.roles || null}
                    name="user.roles"
                    getOptionValue={(option) => option.name}
                    onChange={(selectedOption) =>
                      handleChangeSelect("user.roles", selectedOption)
                    }
                    searchFunction={getListRole}
                    searchObject={{}}
                    errors={formik?.errors?.user?.roles}
                    touched={formik?.touched?.user?.roles}
                    isDisabled={isView}
                  />
                </Col>
              }
              {showPerson &&
                <>
                  <Col xs={4} className="pt-4">
                    <Autocomplete
                      isRequired
                      lable={lang("PERSON.DEPARTMENT_MAIN")}
                      options={departmentListData}
                      value={formik?.values?.departmentObject || null}
                      name="departmentObject"
                      valueSearch={"code"}
                      onChange={(selectedOption) =>
                        handleChangeSelect("departmentDefCode", selectedOption?.code)
                      }
                      errors={formik?.errors?.departmentObject}
                      touched={formik?.touched?.departmentObject}
                      isDisabled={isView}
                    />
                  </Col>
                  <Col xs={8} className="pt-4">
                    <Autocomplete
                      lable={lang("PERSON.DEPARTMENT_RELATED")}
                      options={departmentListData}
                      isMulti
                      value={selectedCodeDepartment}
                      name="listCodeDepartments"
                      getOptionLabel={(option) => option.name}
                      valueSearch={"code"}
                      valueField={"code"}
                      getOptionValue={(option) => option.code}
                      onChange={(selectedOption) => {
                        setSelectedCodeDepartment(selectedOption);
                        handleChangeDepartmentRelated(selectedOption);
                      }
                      }
                      isDisabled={isView}
                    />
                  </Col>
                </>
              }
              {userInfo?.user?.id && (
                <Col xs={3} className="pt-4">
                  <Form.Check
                    type="switch"
                    className="ml-0 spaces mt-24"
                    name="isChangePassword"
                    onChange={(e) =>
                      handleChangeSelect(
                        USER_VARIABLE.IS_CHANGE_PASSWORD,
                        e.target.checked
                      )
                    }
                    checked={formik?.values?.user?.isChangePassword}
                    label={lang("USER.CHANGEPASSWORD")}
                    disabled={isView}
                  />
                </Col>
              )}
              {(!userInfo?.user?.id ||
                formik?.values?.user?.isChangePassword) && (
                  <>
                    <Col xs={3} className="pt-4">
                      <OctTextValidator
                        name="user.password"
                        lable={lang("USER.PASSWORD")}
                        type={"password"}
                        onChange={formik.handleChange}
                        value={formik?.values?.user?.password}
                        touched={formik?.touched?.user?.password}
                        errors={formik?.errors?.user?.password}
                        isRequired
                        disabled={isView}
                        className="custom-text-validator"
                      />
                    </Col>
                    <Col xs={3} className="pt-4">
                      <OctTextValidator
                        name="user.confirmPassword"
                        lable={lang("USER.RE_PASSWORD")}
                        type={"password"}
                        onChange={formik.handleChange}
                        value={formik?.values?.user?.confirmPassword}
                        touched={formik?.touched?.user?.confirmPassword}
                        errors={formik?.errors?.user?.confirmPassword}
                        isRequired
                        disabled={isView}
                        className="custom-text-validator"
                      />
                    </Col>
                  </>
                )}

              <Col xs={3} className="pt-4">
                <Form.Check
                  type="checkbox"
                  name="active"
                  className="ml-0 spaces mt-24"
                  onChange={(e) =>
                    handleChangeSelect("active", e.target.checked)
                  }
                  checked={formik?.values?.active || false}
                  label={lang("USER.ACTIVE")}
                  disabled={isView}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              className="btn btn-secondary  btn-sm"
              onClick={handleCloseDialog}
            >
              {lang("BTN.CANCEL")}
            </Button>
            <Button
              className="btn btn-primary btn-sm"
              type="submit"
              hidden={isView}
            >
              {lang("BTN.SAVE")}
            </Button>
          </Modal.Footer>
        </Form>
        {openOrganizationDialog &&
          <Modal show={openOrganizationDialog} onHide={() => setOpenOrganizationDialog(false)} size="lg" centered>
            <Modal.Header closeButton>
              <Modal.Title>
                {lang("UNIT.LIST")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={7}></Col>
                <Col xs={5}>
                  <OctInputSearch handleSearch={handleGetListOrganization} onChange={(e: any) => setKeyWord(e.target.value)} />
                </Col>
              </Row>
              <OctTableCollapse
                data={orgListData}
                columns={columns}
                actionStyle={{ display: "none" }}
                searchObject={searchObject}
                setSearchObject={setSearchObject}
                updateTableData={handleGetListOrganization}
                nameChildren="subs"
                setData={(value: any) => { }}
                selectData={(value: any) => { }}
                height={400}
              />
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
              <Button
                className="btn btn-secondary  btn-sm"
                onClick={() => setOpenOrganizationDialog(false)}
              >
                {lang("BTN.CANCEL")}
              </Button>
              <Button
                className="btn btn-primary btn-sm"
                onClick={() => handleSelectOrganization()}
              >
                {lang("BTN.SAVE")}
              </Button>
            </Modal.Footer>
          </Modal>
        }
      </Modal>
    </>
  );
};
export { UserDialog };
