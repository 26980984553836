import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import OCTTextValidator from "../../../component/input-field/OCTTextValidator";
import { SEARCH_OBJECT_MAX_SIZE } from "../../../utils/Constant";
import { IRole } from "../../model/ManagerModel";
import {
  addNewRole,
  getListAuth,
  updateRole,
} from "../../services/ManagerServices";
import { INIT_ROLE, findChildrenNamesByValue } from "../const/ManagerCost";
import SelectedPermissions from "./SelectPermission";
import { SUCCESS_CODE } from "../../../constant";
import { toast } from "react-toastify";

interface IProps {
  handleCloseDialog: () => void;
  roleInfo: IRole;
  handleReload: () => Promise<void>;
}
const RolesDialog: FC<IProps> = (props) => {
  const { handleCloseDialog, roleInfo, handleReload } = props;
  const { lang } = useMultiLanguage();

  const [permissionsList, setPermissionsList] = useState<any[]>([]);
  const [roleAdmin, setRoleAdmin] = useState(false);
  const [roleData, setRoleData] = useState(roleInfo);
  const [fullAuthorities, setFullAuthorities] = useState<any[]>([]);
  const [authorities, setAuthorities] = useState<any[]>(
    roleData.authorities || []
  );

  useEffect(() => {
    if (roleInfo?.id) {
      let authoritieConverted = roleInfo?.authorities?.map((item) => ({
        name: item,
      }));
      formik.setValues({
        ...roleInfo,
        authorities: authoritieConverted || [],
      });
      setRoleData(roleInfo);
    }
  }, [roleInfo]);

  useEffect(() => {
    let _permissionsList = convertDataEdit(permissionsList);
    setPermissionsList(_permissionsList);
  }, [authorities]);

  const convertDataEdit = (input: any[]) => {
    // Nếu không có input hoặc input không phải mảng, trả về input
    if (!input || !Array.isArray(input)) {
      return input;
    }

    // Lặp qua từng phần tử trong mảng input
    return input.map((item) => {
      // Tạo một bản sao của đối tượng hiện tại
      const newItem = { ...item };

      // Nếu đối tượng hiện tại có thuộc tính 'children' và 'children' là một mảng
      if (newItem.children && Array.isArray(newItem.children)) {
        // Gọi đệ quy để chuyển đổi các con của đối tượng hiện tại
        newItem.children = convertDataEdit(newItem.children);

        // Kiểm tra xem tất cả các con có được chọn không
        newItem.checkAllChildren = newItem.children.every(
          (child: any) => child.checked
        );
      }

      // Thiết lập thuộc tính 'checked' cho đối tượng hiện tại
      newItem.checked = authorities?.some((item) =>
        item.startsWith(newItem.value)
      );
      // Trả về đối tượng mới đã chuyển đổi
      return newItem;
    });
  };

  const handleConvertData = (data: any[]) => {
    const output: any[] = [];

    // Function to recursively build the tree structure
    function addToTree(node: any, pathArr: any[], nameArr: any[]) {
      if (pathArr.length === 0) return; // Exit condition

      const path = pathArr.shift();
      let childNode = node.children.find((child: any) => child.name === path);
      if (!childNode) {
        childNode = {
          name: path,
          nameNode: nameArr[0],
          value: node.value ? `${node.value}.${path}` : path,
          children: [],
        };
        node.children.push(childNode);
      }
      addToTree(childNode, pathArr, nameArr.slice(1));
    }

    // Loop through each input object
    data.forEach((obj) => {
      const pathArr = obj.code.split("."); // Split the code by '.'
      const nameArr = obj.name.split("."); // Split the code by '.'
      let rootNode = output.find((node) => node.name === pathArr[0]); // Find root node
      if (!rootNode) {
        rootNode = {
          name: pathArr[0],
          nameNode: nameArr[0],
          value: pathArr[0],
          children: [],
        };
        output.push(rootNode);
      }
      addToTree(rootNode, pathArr.slice(1), nameArr.slice(1)); // Add the rest of the path to the tree
    });

    return output;
  };

  const updateData = async () => {
    try {
      // setPageLoading(true);
      const resAccess = await getListAuth(SEARCH_OBJECT_MAX_SIZE);
      const newAccess = resAccess.data.data?.content.filter(
        (item: any) => item.code !== "ROOT_ADMIN"
      );
      const data = handleConvertData(newAccess);
      setFullAuthorities(newAccess.map((item: any) => item?.name));

      if (roleData.id) {
        let permissionsList = convertDataEdit(data);
        setPermissionsList(permissionsList);
      } else {
        setPermissionsList(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      // setPageLoading(false);
    }
  };

  const handleShowListPermission = (keyPermission: string) => {
    let newPermissionsList = [...permissionsList];
    newPermissionsList = newPermissionsList.map((item) => {
      if (item.value === keyPermission) {
        return {
          ...item,
          notShow: !item.notShow,
        };
      } else {
        return item;
      }
    });

    setPermissionsList(newPermissionsList);
  };

  useEffect(() => {
    updateData();
  }, []);

  const checkAndModifyArray = (arr: string[] = [], str: string) => {
    // Kiểm tra xem str có trong mảng hay không
    const index = arr.indexOf(str);

    // Nếu str đã có trong mảng, xóa str khỏi mảng
    if (index !== -1 && index !== undefined) {
      arr.splice(index, 1);
    }
    // Nếu str không có trong mảng, thêm str vào cuối mảng
    else {
      arr.push(str);
    }

    // Trả về mảng sau khi đã được sửa đổi
    return arr;
  };

  const handleCheck = (e: any) => {
    let value = e.target.value;
    let checked = e.target.checked;

    if (fullAuthorities.includes(value)) {
      // Xử lý khi check children nhỏ nhất
      let _authorities = checkAndModifyArray(authorities, value);
      setAuthorities([..._authorities]);
    } else {
      // Xử lý khi check parent bất kỳ
      let childrens = findChildrenNamesByValue(permissionsList, value);
      let _authorities: string[] = [];
      if (checked) {
        _authorities = authorities;
        for (let index = 0; index < childrens.length; index++) {
          if (!authorities.includes(childrens[index])) {
            _authorities.push(childrens[index]);
          }
        }
      } else {
        _authorities = authorities;
        _authorities = _authorities.filter((item) => !childrens.includes(item));
      }
      setAuthorities([..._authorities]);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
  });

  const handleSubmit = async (values: IRole) => {
    try {
      let dataSubmit = { ...values, authorities };
      const res = roleInfo.id
        ? await updateRole(roleInfo.id, dataSubmit)
        : await addNewRole(dataSubmit);
      if (res?.data?.code === SUCCESS_CODE) {
        let message = roleInfo?.id
          ? "TOAST.EDIT.SUCCESS"
          : "TOAST.CREATE.SUCCESS";
        toast.success(lang(message));
        handleReload();
        handleCloseDialog();
      } else {
        toast.error(`${res?.data?.message}`);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const formik = useFormik({
    initialValues: INIT_ROLE,
    onSubmit: handleSubmit,
    validationSchema,
  });

  return (
    <Modal
      show={true}
      onHide={handleCloseDialog}
      size="lg"
      className="roles-dialog"
      centered
      fullscreen
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title className="color-primary">
            {lang(!roleInfo?.id ? "ROLE.ADD" : "ROLE.EDIT")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <Row>
            <Col xs={6} xxl={4}>
              <OCTTextValidator
                isRequired
                name="name"
                lable={lang("AUTH.NAME")}
                type="text"
                value={formik.values?.name || ""}
                onChange={formik.handleChange}
                errors={formik.errors?.name}
                touched={formik.touched?.name}
              />
            </Col>
            <Col xs={6} xxl={8}>
              <OCTTextValidator
                name="description"
                lable={lang("ROLE.DES")}
                type="text"
                value={formik.values?.description || ""}
                onChange={formik.handleChange}
                errors={formik.errors?.description}
                touched={formik.touched?.description}
              />
            </Col>
          </Row>
          <Row className="pt-6 tree-permission">
            <SelectedPermissions
              data={permissionsList}
              roleAdmin={roleAdmin}
              handleShowListPermission={handleShowListPermission}
              handleCheck={handleCheck}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            className="btn btn-secondary btn-sm"
            onClick={handleCloseDialog}
          >
            {lang("BTN.CANCEL")}
          </Button>
          <Button className="btn btn-primary btn-sm" type="submit">
            {lang("BTN.SAVE")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export { RolesDialog };
