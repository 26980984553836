import { Formik, useFormik } from "formik";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { addNewOrganization, updateOrganization } from "../../services/PersonServices";
import { PersonModels, PersonDialogProps } from "../../model/PersonModels";
import Autocomplete from "../../../component/input-field/Autocomplete";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import TextValidator from "../../../component/input-field/OCTTextValidator";
import { handleThrowResponseMessage, isSuccessfulResponse } from "../../../../AppFunction";
import AppContext from "../../../../AppContext";
import { INIT_PERSON } from "../../const/PersonConst";
import { addNewPerson, updatePerson } from "../../services/PersonServices";
import { LIST_GENDER, REGEX, SEARCH_OBJECT_MAX_SIZE, TYPE, VARIABLE_STRING } from "../../../utils/Constant";
import { Org, User, UserByOrg } from "../../../manager/model/UserModels";
import { UserDialog } from "../../../manager/component/Dialog/UserDialog";
import { columnNamesTypeTableCollapse, OctInputSearch, OctTableCollapse, OctTextValidator } from "@oceantech/oceantech-ui";
import moment from "moment";
import { USER_VARIABLE } from "../../../manager/component/const/UserConst";
import { getListDepartment, getListRole } from "../../../manager/services/ManagerServices";
import { VARIABLE } from "../../../../Constant";
import { IObject } from "../../../models/models";
import { getListOrganizations, getOrganizationById } from "../../../manager/services/OrganizationServices";
import { addNewUser, updateUser } from "../../../manager/services/UserServices";


const PersonDialog: FC<PersonDialogProps> = (props) => {
  const { handleCloseDialog, personInfo = INIT_PERSON, isView = false } = props;

  const { lang } = useMultiLanguage();
  const intl = useIntl();
  const { setPageLoading } = useContext(AppContext);
  const orgListDataRef = useRef<Org[]>([]);

  const [isShowUser, setIsShowUser] = useState<boolean>(false);
  const [orgName, setOrgName] = useState<any>(null);
  const [openOrganizationDialog, setOpenOrganizationDialog] = useState<boolean>(false);
  const [departmentListData, setDepartmentListData] = useState<Org[]>([]);
  const [selectedCodeDepartment, setSelectedCodeDepartment] = useState<Org[]>([]);
  const [searchObject, setSearchObject] = useState<IObject>({ pageIndex: 1, pageSize: 1000 });
  const [orgListData, setOrgListData] = useState<Org[]>([]);
  const [keyWord, setKeyWord] = useState<any>(null);

  const columns: columnNamesTypeTableCollapse[] = [
    {
      name: "",
      field: "",
      headerStyle: {
        minWidth: "50px",
      },
      cellStyle: {
        minWidth: "50px",
        textAlign: "center",
        justifyContent: "center"
      },
      render(data, index, numericalOrder, itemList) {
        return (
          <div className='d-flex align-items-center justify-content-center custom-checkbox'>
            <input
              className='w-18px h-18px'
              type='checkbox'
              checked={data?.checked || false}
              onClick={() => handleCheckboxClick(data)}
            />
          </div>
        )
      },
    },
    {
      name: lang("UNIT.NAME"),
      field: "name",
      headerStyle: {
        minWidth: "350px",
      },
      cellStyle: {
        minWidth: "350px",
      },
      isCollapse: true,
    },
    {
      name: lang("UNIT.CODE"),
      field: "code",
      type: TYPE.NUMBER,
      headerStyle: {
        minWidth: "120px",
        maxWidth: "150px",
      },
      cellStyle: {
        minWidth: "120px",
        maxWidth: "150px",
      },
    },
    {
      name: lang("UNIT.TYPE"),
      field: "type",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        minWidth: "150px",
      },
    },
  ];

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    gender: Yup.string().nullable().required(lang("VALIDATION.REQUIRE")),
    birthDate: Yup.string().required(lang("VALIDATION.REQUIRE")).test('is-valid-date', lang("VALIDATION.INVALID_BIRTHDATE"), value => validateDate(value)),
    email: Yup.string().email(lang("VALIDATION.EMAILINVALID")).nullable(true).required(lang("VALIDATION.REQUIRE")),
    phoneNumber: Yup.string()
      .matches(REGEX.CHECK_PHONE, lang("VALIDATION.ISPHONE"))
      .nullable(true).required(lang("VALIDATION.REQUIRE")),
    departmentObject: Yup.object().nullable(true).required(lang("VALIDATION.REQUIRE")),
    user: Yup.object().shape({
      email: Yup.string().email(lang("VALIDATION.EMAILINVALID")).nullable(true),
      phoneNumber: Yup.string()
        .matches(REGEX.CHECK_PHONE, lang("VALIDATION.ISPHONE"))
        .nullable(true),
      password: isShowUser
        ? Yup.string()
          .required(lang("VALIDATION.REQUIRE"))
          .min(8, lang("REQUIRE_8"))
          .nullable()
        : Yup.mixed().nullable(),
      confirmPassword: isShowUser
        ? Yup.string()
          .required(lang("VALIDATION.REQUIRE"))
          .oneOf([Yup.ref("password"), null], lang("PASSWORD_INVALID"))
          .nullable()
        : Yup.mixed().nullable(),
      username: isShowUser ? Yup.string().required(lang("VALIDATION.REQUIRE")).nullable() : Yup.mixed().nullable(),
      roles: isShowUser ? Yup.array()
        .of(Yup.object())
        .required(lang("VALIDATION.REQUIRE"))
        .nullable(true) : Yup.mixed().nullable(),
    }),
  });

  const convertSubmitData = (values: PersonModels) => {
    const monentBirthDate = moment(values.birthDate, "DD/MM/YYYY");
    delete values.departments;
    return ({
      ...values,
      birthDate: monentBirthDate.format("YYYY-MM-DD"),
      dobDay: monentBirthDate.date(),
      dobMonth: monentBirthDate.month() + 1,
      dobYear: monentBirthDate.year(),
      idNumber: values.idNumber,
      codeDepartments: values.codeDepartments,
      departmentDefault: values.departmentObject,
      user: {
        ...values.user,
        fullName: values?.fullName,
        gender: values?.gender,
        phone: values?.phoneNumber,
        email: values?.email,
        password:
          personInfo?.user?.id && !values.user?.isChangePassword
            ? VARIABLE.DEFAULT_PASSWORD
            : values?.user?.password,
        confirmPassword:
          personInfo?.user?.id && !values.user?.isChangePassword
            ? VARIABLE.DEFAULT_PASSWORD
            : values?.user?.confirmPassword,
        accountNonExpired: true,
        accountNonLocked: true,
        active: values.active,
        // isChangePassword: values.user?.isChangePassword,
        credentialsNonExpired: true,
        justCreated: true,
        orgId: values?.orgId,
        roles: values.user?.roles?.map((item: any) => item.name),
      },
    })
  };

  const convertSubmitDataUser = (values: PersonModels) => ({
    ...values,
    ...values.user,
    phone: values?.phoneNumber,
    email: values?.email,
    orgId: values?.orgId,
    password:
      personInfo?.user?.id && !values.user?.isChangePassword
        ? VARIABLE.DEFAULT_PASSWORD
        : values?.user?.password,
    confirmPassword:
      personInfo?.user?.id && !values.user?.isChangePassword
        ? VARIABLE.DEFAULT_PASSWORD
        : values?.user?.confirmPassword,
    accountNonExpired: true,
    accountNonLocked: true,
    active: values.active,
    isChangePassword: values.user?.isChangePassword,
    credentialsNonExpired: true,
    justCreated: true,
    roles: values.user?.roles?.map((item: any) => item.name),
  });

  const handleSubmit = async (values: PersonModels) => {
    try {
      setPageLoading(true);
      const dataSubmit = convertSubmitData(values);
      const dataUser: any = convertSubmitDataUser(values);
      let res: any; 
      if (isShowUser) {
        const resUser: any = await addNewUser(dataUser);
        if (isSuccessfulResponse(resUser?.data?.code)) {
          const dataSend = {
              ...dataSubmit,
              userId: resUser?.data?.data.id,
              user: {
                ...dataSubmit.user,
                userId: resUser?.data?.data.id
              }
            }
          res = await addNewPerson(dataSend as PersonModels);
        } else {
          toast.error(lang("TOAST.CREATE.ERROR"));
        }
      } else {
        const dataSend = dataSubmit as PersonModels;
        if (personInfo.id) {
          delete dataSend.user;
        };
        res = personInfo.id ? await updatePerson(personInfo.id, dataSend )
        : await addNewPerson(dataSend);
      }
      if (isSuccessfulResponse(res?.data?.code)) {
        toast.success(
          personInfo.id
            ? lang("TOAST.EDIT.SUCCESS")
            : lang("TOAST.CREATE.SUCCESS")
        );
        handleCloseDialog();
      } else {
        handleThrowResponseMessage(res);
      }
    } catch(err) {
      console.error("ERROR", err);
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: INIT_PERSON,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!personInfo?.id) {
      handleGetListOrganization()
    } else {
      formik.setValues({
        ...personInfo,
        birthDate: personInfo?.birthDate ? moment(personInfo?.birthDate).format("DD/MM/YYYY") : "",
      });
      setSelectedCodeDepartment(personInfo?.departments);
    }
  }, [personInfo]);

  useEffect(() => {
    if (formik.values?.orgId) {
      handleGetListDepartmentByOrg(formik.values?.orgId);
      init(formik.values?.orgId);
    };
  }, [formik.values?.orgId]);

  useEffect(() => {
    handleCheckDepartmentSelect();
  }, [formik.values?.departmentDefault, departmentListData]);

  const init = async (orgId: string) => {
    await handleGetListOrganization(orgId);
    await handleGetOrganizationById(orgId);
  };

  const validateDate = (value: string | null | undefined): boolean => {
    if (!value) {
      return false;
    }
    const formats = ['YYYY', 'DD/MM/YYYY'];
    return formats.some(format => moment(value, format, true).isValid());
  };

  const handleChangeSelect = (name: string, data: any) => {
    switch (name) {
      case VARIABLE_STRING.USER_ROLES:
        formik.setFieldValue(name, data);
        formik.setFieldValue("employee", null);
        break;
      case USER_VARIABLE.IS_CHANGE_PASSWORD:
        formik.setFieldValue(name, data);
        formik.setFieldValue("confirmPassword", "");
        break;
      default:
        formik.setFieldValue(name, data);
    }
  };

  const handleChangeDepartmentRelated = (listSelected: Org[]) => {
    const listCodeDepartment = listSelected?.map(item => item.code);
    handleChangeSelect("codeDepartments", listCodeDepartment);
  };

  const handleGetListOrganization = async (orgId?: string) => {
    try {
      const res = await getListOrganizations({ ...searchObject, keyword: keyWord, orgId: orgId });
      const { code, data } = res.data;
      if (isSuccessfulResponse(code)) {
        setOrgListData(data?.content);
        orgListDataRef.current = data?.content;
      } else {
        handleThrowResponseMessage(res);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };
  
  const handleGetListDepartmentByOrg = async (orgId?: string) => {
    try {
      if (!orgId) return;
      const res = await getListDepartment({ ...searchObject, orgId: orgId });
      const { code, data } = res.data;
      if (isSuccessfulResponse(code)) {
        setDepartmentListData(data?.content);
      } else {
        handleThrowResponseMessage(res);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleGetOrganizationById = async (id: any) => {
    try {
      if (!id) return;
      const res = await getOrganizationById(id);
      const { code, data } = res.data;
      if (isSuccessfulResponse(code)) {
        setOrgName(data?.name);
        handleCheckboxClick(data);
      } else {
        handleThrowResponseMessage(res);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleCheckDepartmentSelect = () => {
    const findItem = departmentListData.find((item) => item.code === formik.values.departmentDefault?.code);
    if (findItem) {
      formik.setFieldValue("departmentObject", findItem);
    } else {
      formik.setFieldValue("departmentObject", null);
    }
  };

  const handleCheckboxClick = (data: any) => {
    const updateCheckedStatus = (items: any[], id: string, checked: boolean): any[] => {
      return items.map(item => {
        if (item.id === id) {
          return {
            ...item,
            checked: checked,
            subs: updateCheckedStatus(item.subs || [], id, checked)
          };
        } else {
          return {
            ...item,
            checked: item.id === data?.parentId ? item.checked : false,
            subs: updateCheckedStatus(item.subs || [], id, checked)
          };
        }
      });
    };
    const updatedList = updateCheckedStatus(orgListDataRef.current, data?.id, true);
    setOrgListData(updatedList);
    orgListDataRef.current = updatedList;
  };

  const handleSelectOrganization = () => {
    setSelectedCodeDepartment([]);
    formik?.setFieldValue("departmentObject", null);
    const findCheckedItem = (items: any[]): any => {
      for (const item of items) {
        if (item.checked) {
          return item;
        }
        if (item.subs.length > 0) {
          const checkedItem = findCheckedItem(item.subs);
          if (checkedItem) {
            return checkedItem;
          }
        }
      }
      return null;
    };
  
    const checkedItem = findCheckedItem(orgListData);
    if (checkedItem) {
      formik.setFieldValue("orgId", checkedItem.id);
      setOrgName(checkedItem.name);
      setOpenOrganizationDialog(false);
    } else {
      toast.warning(lang("TOAST.SELECT_ORGANIZATION"));
    }
  };

  return (
    <Modal show={true} onHide={handleCloseDialog} size="lg" centered>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isView ? lang("PERSON.VIEW") : lang(personInfo?.id ? "PERSON.EDIT" : "PERSON.ADD")}

            <i
              className={`bi ${isView ? "bi-eye" : personInfo?.id ? "bi-pencil-square" : " bi-plus-circle"
                } text-primary ps-2 fs-5`}
            ></i>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="px-3">
            <Col xs={3}>
              <div className="fw-bold spaces fs-16 mb-8 text-primary">{lang("PERSON.PERSONAL-INFOR")}</div>
            </Col>
            <Col xs={9}>
              {!formik.values?.user?.id && (
                <Col xs={4}>
                  <Form.Check
                    type="switch"
                    className="ml-0 spaces mt-5"
                    name="isCreatePerson"
                    onChange={(e) => setIsShowUser(e.target.checked)}
                    checked={isShowUser}
                    label={lang("PERSON.CREATE-ACCOUNT")}
                  />
                </Col>
              )}
            </Col>
            <Col xs={4}>
              <OctTextValidator
                name="fullName"
                lable={lang("WELFARE.EMPLOYEE.FULLNAME")}
                type={"text"}
                onChange={formik.handleChange}
                value={formik?.values?.fullName}
                errors={formik?.errors?.fullName}
                touched={formik?.touched?.fullName}
                isRequired
                disabled={isView}
                className="custom-text-validator"
              />
            </Col>
            <Col xs={4}>
              <Autocomplete
                isRequired
                lable={lang("PERSON.GENDER")}
                options={LIST_GENDER}
                value={LIST_GENDER.find(item => item.code === formik?.values?.gender) || null}
                name="gender"
                onChange={(selectedOption) =>
                  handleChangeSelect("gender", selectedOption?.code)
                }
                errors={formik?.errors?.gender}
                touched={formik?.touched?.gender}
                isDisabled={isView}
              />
            </Col>
            <Col xs={4}>
              <OctTextValidator
                name="birthDate"
                lable={lang("INPUT.BIRTHDAY")}
                type={"text"}
                placeholder="DD/MM/YYYY"
                onChange={formik.handleChange}
                value={formik?.values?.birthDate}
                errors={formik?.errors?.birthDate}
                touched={formik?.touched?.birthDate}
                isRequired
                disabled={isView}
                className="custom-text-validator"
              />
            </Col>
            <Col xs={4} className="pt-4">
              <OctTextValidator
                name="idNumber"
                lable={lang("PERSON.ID_NUMBER")}
                type={"text"}
                onChange={formik.handleChange}
                value={formik?.values?.idNumber}
                errors={formik?.errors?.idNumber}
                touched={formik?.touched?.idNumber}
                disabled={isView}
                className="custom-text-validator"
              />
            </Col>
            <Col xs={4} className="pt-4">
              <OctTextValidator
                name="email"
                lable={lang("USER.EMAIL")}
                type={"text"}
                onChange={formik.handleChange}
                value={formik?.values?.email}
                isRequired
                errors={formik?.errors?.email}
                touched={formik?.touched?.email}
                disabled={isView}
                className="custom-text-validator"
              />
            </Col>
            <Col xs={4} className="pt-4">
              <OctTextValidator
                name="phoneNumber"
                lable={lang("USER.PHONE")}
                type={"text"}
                onChange={formik.handleChange}
                value={formik?.values?.phoneNumber}
                isRequired
                errors={formik?.errors?.phoneNumber}
                touched={formik?.touched?.phoneNumber}
                disabled={isView}
                className="custom-text-validator"
              />
            </Col>
            <Col xs={6} className="pt-3">
              <Row>
                <Col xs={isView ? 12 : 9} >
                  <OctTextValidator
                    isRequired
                    name="orgName"
                    lable={lang("GENERAL.CURRENT_ORGANIZATION")}
                    type={"text"}
                    value={orgName}
                    readOnly
                    disabled={isView}
                    className="custom-text-validator"
                  />
                </Col>
                {!isView &&
                  <Col xs={3} className="pt-7">
                    <Button
                      className="btn btn-primary btn-sm"
                      onClick={() => setOpenOrganizationDialog(true)}
                      hidden={isView}
                    >
                      {lang("BTN.SELECT")}
                    </Button>
                  </Col>
                }
              </Row>
            </Col>
            <Col xs={6} className="pt-4">
              <Autocomplete
                isRequired
                lable={lang("PERSON.DEPARTMENT_MAIN")}
                options={departmentListData}
                value={formik?.values?.departmentObject || null}
                name="departmentObject"
                valueSearch={"code"}
                onChange={(selectedOption) => { 
                  handleChangeSelect("departmentDef", selectedOption?.code);
                  handleChangeSelect("departmentObject", selectedOption);
                }}
                isClearable={true}
                errors={formik?.errors?.departmentObject}
                touched={formik?.touched?.departmentObject}
                isDisabled={isView}
              />
            </Col>
            <Col xs={12} className="pt-4">
              <Autocomplete
                lable={lang("PERSON.DEPARTMENT_RELATED")}
                options={departmentListData}
                isMulti
                value={selectedCodeDepartment}
                name="listCodeDepartments"
                getOptionLabel={(option) => option.name}
                valueSearch={"code"}
                valueField={"code"}
                getOptionValue={(option) => option.code}
                onChange={(selectedOption) => {
                  setSelectedCodeDepartment(selectedOption);
                  handleChangeDepartmentRelated(selectedOption);
                }
                }
                isDisabled={isView}
              />
            </Col>
          </Row>
          {isShowUser &&
            <>
              <Row className="p-3">
                <Col xs={12}>
                  <div className="fw-bold spaces fs-16 mb-8 text-primary">{lang("ACCOUNT-INFOR")}</div>
                </Col>

                <Col xs={4}>
                  <OctTextValidator
                    name="user.username"
                    lable={lang("USER.ACCOUNT")}
                    type={"text"}
                    onChange={formik.handleChange}
                    value={formik?.values?.user?.username}
                    errors={(formik?.errors?.user as any)?.username}
                    touched={(formik?.errors?.user as any)?.username}
                    readOnly={Boolean(personInfo.user?.id)}
                    isRequired
                    disabled={isView}
                    className="custom-text-validator"
                  />
                </Col>
                <Col xs={4}>
                  <OctTextValidator
                    name="user.password"
                    lable={lang("USER.PASSWORD")}
                    type={"password"}
                    onChange={formik.handleChange}
                    value={formik?.values?.user?.password}
                    touched={(formik?.errors?.user as any)?.password}
                    errors={(formik?.errors?.user as any)?.password}
                    isRequired
                    // disabled={isView}
                    className="custom-text-validator"
                  />
                </Col>
                <Col xs={4}>
                  <OctTextValidator
                    name="user.confirmPassword"
                    lable={lang("USER.RE_PASSWORD")}
                    type={"password"}
                    onChange={formik.handleChange}
                    value={formik?.values?.user?.confirmPassword}
                    touched={(formik?.errors?.user as any)?.confirmPassword}
                    errors={(formik?.errors?.user as any)?.confirmPassword}
                    isRequired
                    // disabled={isView}
                    className="custom-text-validator"
                  />
                </Col>
                <Col xs={personInfo?.user?.id ? 6 : 9} className="pt-4">
                  <Autocomplete
                    isRequired
                    lable={lang("USER.ROLE")}
                    options={[]}
                    isMulti
                    value={formik?.values?.user?.roles || null}
                    name="user.roles"
                    getOptionValue={(option) => option.name}
                    onChange={(selectedOption) =>
                      handleChangeSelect("user.roles", selectedOption)
                    }
                    searchFunction={getListRole}
                    searchObject={{}}
                    errors={(formik?.errors?.user as any)?.roles}
                    touched={(formik?.errors?.user as any)?.roles}
                    isDisabled={isView}
                  />
                </Col>
                {personInfo?.user?.id && (
                  <Col xs={3} className="pt-5">
                    <Form.Check
                      type="switch"
                      className="ml-0 spaces mt-24"
                      name="isChangePassword"
                      onChange={(e) =>
                        handleChangeSelect(
                          USER_VARIABLE.IS_CHANGE_PASSWORD,
                          e.target.checked
                        )
                      }
                      checked={formik?.values?.user?.isChangePassword}
                      label={lang("USER.CHANGEPASSWORD")}
                      disabled={isView}
                    />
                  </Col>
                )}
                <Col xs={3} className="pt-5">
                  <Form.Check
                    type="checkbox"
                    name="active"
                    className="ml-0 spaces mt-24"
                    onChange={(e) =>
                      handleChangeSelect("active", e.target.checked)
                    }
                    checked={formik?.values?.active || false}
                    label={lang("USER.ACTIVE")}
                    disabled={isView}
                  />
                </Col>
              </Row>
            </>
          }
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            className="btn btn-secondary  btn-sm"
            onClick={handleCloseDialog}
          >
            {lang("BTN.CANCEL")}
          </Button>
          <Button
            className="btn btn-primary btn-sm"
            type="submit"
            hidden={isView}
          >
            {lang("BTN.SAVE")}
          </Button>
        </Modal.Footer>
      </Form>
      {openOrganizationDialog &&
        <Modal show={openOrganizationDialog} onHide={() => setOpenOrganizationDialog(false)} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {lang("UNIT.LIST")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={7}></Col>
              <Col xs={5}>
                <OctInputSearch handleSearch={handleGetListOrganization} onChange={(e: any) => setKeyWord(e.target.value)} />
              </Col>
            </Row>
            <OctTableCollapse
              data={orgListData}
              columns={columns}
              actionStyle={{ display: "none" }}
              searchObject={searchObject}
              setSearchObject={setSearchObject}
              updateTableData={handleGetListOrganization}
              nameChildren="subs"
              setData={(value: any) => { }}
              selectData={(value: any) => { }}
              height={400}
            />
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              className="btn btn-secondary  btn-sm"
              onClick={() => setOpenOrganizationDialog(false)}
            >
              {lang("BTN.CANCEL")}
            </Button>
            <Button
              className="btn btn-primary btn-sm"
              onClick={() => handleSelectOrganization()}
            >
              {lang("BTN.SAVE")}
            </Button>
          </Modal.Footer>
        </Modal>
      }
    </Modal>
  );
};
export { PersonDialog };
